<template>
  <div>
    <b-card
      no-body
      class="p-1 mb-0"
    >
      <div class="d-flex align-items-center">
        <div class="title mr-1">
          Camas en fuera de servicio
        </div>
        <router-link
          to="/historial-de-camas"
          class="history"
        >
          Historial
        </router-link>
      </div>
      <b-table
        responsive
        :fields="fields"
        :items="records"
        show-empty
        :busy="busy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Cargando datos...</strong>
          </div>
        </template>
        <template #empty>
          <h4 class="text-center py-2">
            No hay registros
          </h4>
        </template>
        <template #cell(actions)="data">
          <div class="d-flex align-items-center">
            <b-button
              v-b-tooltip.hover.top="'Quitar fuera de servicio'"
              variant="success"
              class="btn-icon mr-25"
              size="sm"
              @click="removeOutService(data.item)"
            >
              <feather-icon icon="CheckCircleIcon" />
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
	BCard,
	BButton,
  BTable,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BSpinner,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    records: {
      type: Array,
      default: () => [],
    },
    busy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [
				{
					key: 'location.name',
					label: 'Cama',
				},
				{
					key: 'observations',
					label: 'Observación',
				},
				{
					key: 'actions',
					label: 'Acciones',
				},
			],
    }
  },
  methods: {
    removeOutService(item) {
      const me = this
      me.$emit('removeOutService', item.id)
    },
  },
}
</script>

<style lang="scss" scoped>
  .title {
    font-size: 18px;
    color: #636363;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .history {
    font-size: 16px;
    color: #3d1fad;
    font-weight: bold;
    margin-bottom: 10px;
  }
</style>
