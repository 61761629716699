<template>
  <div>
    <b-form @submit.prevent>
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Descripción técnica"
            label-for="technical_observations"
          >
            <b-form-textarea
              id="technical_observations"
              v-model="formData.technical_observations"
              placeholder="Descripción técnica"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Fecha y hora de finalización"
            label-for="finished_date"
          >
            <b-form-input
              id="finished_date"
              v-model="formData.finished_date"
              type="datetime-local"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" />
        <b-col
          md="6"
          class="text-right"
        >
          <b-button
            type="button"
            variant="primary"
            :disabled="isBusy || $v.$invalid"
            @click="saveData"
          >
            <b-spinner
              v-if="isBusy"
              small
            />
            <v-icon
              v-else
              name="save"
            />
            Guardar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
	BForm,
	BFormGroup,
	BButton,
	BCol,
	BFormInput,
	BRow,
	BSpinner,
	BFormTextarea,
} from 'bootstrap-vue'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'

export default {
	inject: ['bedBlockingRepository'],
	components: {
		BForm,
		BFormGroup,
		BButton,
		BCol,
		BRow,
		BSpinner,
		BFormTextarea,
    BFormInput,
	},
	props: {
		bedId: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			formData: {
				technical_observations: '',
        finished_date: '',
        finished: true,
			},
			isBusy: false,
		}
	},
	validations: {
		formData: {
      technical_observations: {
        required,
      },
      finished_date: {
        required,
      },
    },
	},
	methods: {
		async saveData() {
			const me = this
			try {
        me.isBusy = true
				me.formData.id = me.bedId
        me.formData.finished_date = moment(me.formData.finished_date).format('YYYY-MM-DD HH:mm:ss')

				await me.bedBlockingRepository.update(
					me.formData.id,
					me.formData,
				)
        this.$bvModal.hide('modal-remove-blocking-bed')
        me.$emit('save')
			} catch (error) {
				// console.log(error)
			} finally {
        me.isBusy = false
      }
		},
	},
}
</script>
