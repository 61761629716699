<template>
  <div>
    <b-form @submit.prevent>
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Descripción de bloqueo de cama"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="formData.observations"
              placeholder="Descripción"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" />
        <b-col
          md="6"
          class="text-right"
        >
          <b-button
            type="button"
            variant="primary"
            :disabled="isBusy || $v.$invalid"
            @click="saveData"
          >
            <b-spinner
              v-if="isBusy"
              small
            />
            <v-icon
              v-else
              name="save"
            />
            Guardar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
	BForm,
	BFormGroup,
	BButton,
	BCol,
	BFormInput,
	BRow,
	BSpinner,
	BFormTextarea,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import { required } from 'vuelidate/lib/validators'

export default {
	inject: ['bedBlockingRepository'],
	components: {
		BForm,
		BFormGroup,
		BButton,
		BCol,
		BRow,
		BSpinner,
		BFormTextarea,
	},
	props: {
		record: {
			type: Object,
			default: null,
		},
		locationId: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			formData: {
        location_id: null,
				observations: '',
			},
			isBusy: false,
		}
	},
	async mounted() {
		const me = this
		if (me.record) {
			me.formData = { ...me.record }
		}
	},
	validations: {
		formData: {
			observations: {
				required,
			},
		},
	},
	methods: {
		async saveData() {
			const me = this
			try {
        me.isBusy = true
				me.formData.location_id = me.locationId
				const uuid = me.formData.id ? me.formData.id : uuidv4()
				const option = me.formData.id ? 'update' : 'save'

				await me.bedBlockingRepository[option](
					uuid,
					me.formData,
				)
        this.$bvModal.hide('modal-blocking-bed')
        me.$emit('save')
			} catch (error) {
				// console.log(error)
			} finally {
        me.isBusy = false
      }
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
