<template>
  <div>
    <b-card
      no-body
      class="p-1"
    >
      <filter-bed @loadBed="loadBed" />
      <b-row>
        <b-col
          cols="12"
          class="mb-1"
        >
          <div class="d-flex">
            <div class="d-flex align-items-center mr-1">
              <b-badge class="unoccupied mr-25">
                {{ unoccupied.length }}
              </b-badge>
              <div class="state_text">
                Desocupada
              </div>
            </div>
            <div class="d-flex align-items-center mr-1">
              <b-badge class="occupied mr-25">
                {{ occupied.length }}
              </b-badge>
              <div class="state_text">
                Ocupada
              </div>
            </div>
            <!-- <div class="d-flex align-items-center mr-1">
              <b-badge class="outofservice mr-25">
                0
              </b-badge>
              <div class="state_text">
                Fuera de servicio
              </div>
            </div>
            <div class="d-flex align-items-center mr-1">
              <b-badge class="maintenance mr-25">
                0
              </b-badge>
              <div class="state_text">
                En mantenimiento
              </div>
            </div> -->
          </div>
        </b-col>
        <b-col cols="12">
          <b-table
            small
            responsive
            :fields="fields"
            :items="records"
            show-empty
          >
            <template #empty>
              <h4 class="text-center py-2">
                No hay registros
              </h4>
            </template>
            <template #cell(bedTemp)="data">
              <div class="d-flex align-items-center">
                <div>
                  <div class="font-weight-bolder">
                    {{ data.item.name }}
                  </div>
                  <b-badge variant="dark">
                    -°C
                  </b-badge>
                </div>
              </div>
            </template>
            <template #cell(status)="data">
              <div
                class="state_circel mr-25"
                :class="stateColor(data.item.location_patient)"
              />
            </template>
            <template #cell(actions)="data">
              <div class="d-flex align-items-center">
                <b-button
                  v-b-tooltip.hover.top="'Dejar fuera de servicio'"
                  variant="danger"
                  class="btn-icon mr-25"
                  size="sm"
                  @click="outService(data.item)"
                >
                  <feather-icon icon="AlertTriangleIcon" />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
	BButton,
  BTable,
  BSpinner,
  VBTooltip,
  BBadge,
} from 'bootstrap-vue'
import PermissionMixin from '@/mixins/PermissionMixin'
import FilterBed from './FilterBed'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    FilterBed,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  mixins: [PermissionMixin],
  data() {
    return {
      records: [],
      fields: [
				{
					key: 'bedTemp',
					label: 'Cama - Temp',
				},
				{
					key: 'status',
					label: 'Estado',
				},
				{
					key: 'actions',
					label: 'Acciones',
				},
			],
      unoccupied: [],
      occupied: [],
    }
  },
  methods: {
    loadBed(items) {
      const me = this
      items.forEach(el => {
        if (el.bed_request && el.bed_request.length > 0) {
          const bedActive = el.bed_request.find(x => x.finished_date == null)
          if (!bedActive) me.records.push(el)
        } else {
          me.records.push(el)
        }
      })

      me.records.forEach(el => {
        if (el.location_patient) {
          me.occupied.push(el)
        } else {
          me.unoccupied.push(el)
        }
      })
    },
    stateColor(item) {
      let color = null
      if (item) {
        color = 'occupied'
      } else {
        color = 'unoccupied'
      }

      return color
    },
    outService(item) {
      const me = this
      me.$emit('outService', item.id)
    },
  },
}
</script>

<style lang="scss">
  .state_circel {
    width: 25px;
    height: 25px;
    border-radius: 0.358rem;
    background: gray;
  }

  .state_text {
    font-size: 14px;
    color: #636363;
  }

  .occupied {
    background-color: #e2322c;
    font-size: 16px;
  }

  .maintenance {
    background-color: #1b3b8d;
    font-size: 16px;
  }

  .unoccupied {
    background-color: #75ff75;
    font-size: 16px;
  }

  .outofservice{
    background-color: #e45820f1;
    font-size: 16px;
  }

</style>
