<template>
  <div>
    <b-row class="mb-1">
      <b-col cols="12">
        <list-bed-blocking
          :records="records"
          :is-busy="isBusy"
          @removeOutService="removeOutService"
        />
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="12">
        <list-bed @outService="outService" />
      </b-col>
    </b-row>
    <b-modal
      id="modal-blocking-bed"
      centered
      title="Dejar cama fuera de servicio"
      hide-footer
    >
      <form-blocking-bed
        :record="record"
        :location-id="locationId"
        @save="loadBedBloking"
      />
    </b-modal>
    <b-modal
      id="modal-remove-blocking-bed"
      centered
      title="Quitar cama ce fuera de servicio"
      hide-footer
    >
      <form-remove-blocking-bed
        :bed-id="bedId"
        @save="loadBedBloking"
      />
    </b-modal>
  </div>
</template>

<script>
import {
	BRow,
	BCol,
  BModal,
} from 'bootstrap-vue'
import ListBed from './components/ListBed'
import ListBedBlocking from './components/ListBedBlocking'
import FormBlockingBed from './components/FormBlockingBed'
import FormRemoveBlockingBed from './components/FormRemoveBlockingBed'

export default {
  inject: ['bedBlockingRepository'],
  components: {
    BRow,
    BCol,
    BModal,
    ListBed,
    FormBlockingBed,
    FormRemoveBlockingBed,
    ListBedBlocking,
  },
  data() {
    return {
      record: null,
      locationId: null,
      bedId: null,
      records: [],
      isBusy: false,
    }
  },
  mounted() {
    const me = this
    me.loadBedBloking()
  },
  methods: {
    outService(id) {
      const me = this
      me.locationId = id
      me.$bvModal.show('modal-blocking-bed')
    },
    async loadBedBloking() {
      const me = this
      me.records = []
      try {
        me.isBusy = true
        const { data } = await me.bedBlockingRepository.getAll()
        data.forEach(el => {
          if (el.finished_date == '' && el.technical_observations == '') me.records.push(el)
        })
      } catch (error) {
        // console.log(error)
      } finally {
        me.isBusy = false
      }
    },
    removeOutService(id) {
      const me = this
      me.bedId = id
      me.$bvModal.show('modal-remove-blocking-bed')
    },
  },
}
</script>

<style>

</style>
