<template>
  <b-row>
    <b-col
      md="4"
      class="mb-1"
    >
      <div class="d-flex align-items-center">
        <div class="title_ mr-1">
          Sede
        </div>
        <v-select
          label="text"
          :options="brands"
          class="select"
          placeholder="Seleccione sede"
          @input="loadServiceByBrand"
        />
      </div>
    </b-col>
    <b-col
      md="4"
      class="mb-1"
    >
      <div class="d-flex align-items-center">
        <div class="title_ mr-1">
          Servicio
        </div>
        <v-select
          v-model="serviceSelected"
          label="text"
          :options="services"
          :reduce="(option) => option.id"
          class="select"
          placeholder="Seleccione servicio"
          @input="loadAreaByService"
        />
      </div>
    </b-col>
    <b-col
      md="4"
      class="mb-1"
    >
      <div class="d-flex align-items-center">
        <div class="title_ mr-1">
          Area
        </div>
        <v-select
          v-model="areaSelected"
          label="text"
          :options="areas"
          class="select"
          placeholder="Seleccione area"
          @input="loadLocationPatient"
        />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
 BCard, BRow, BCol, BButton, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
	inject: ['patientBedRepository'],
	components: {
		BRow,
		BCol,
		vSelect,
	},
	data() {
		return {
			customerId: JSON.parse(localStorage.getItem('user')).customer_id,
			services: [],
			areas: [],
			brands: [],
			locationPatients: [],
			serviceSelected: null,
			areaSelected: null,
		}
	},
	mounted() {
		this.loadBrandByClient()
	},
	methods: {
		async loadBrandByClient() {
			const me = this
			try {
				const res = await me.patientBedRepository.getAllBrandByClient(
					me.customerId,
				)
				res.forEach(element => {
					me.brands.push({ id: element.id, text: element.name })
				})
			} catch (error) {
				// console.log(error)
			}
		},
		async loadServiceByBrand(item) {
			const me = this
      me.resetSelect('1')
			try {
				const { data } = await me.patientBedRepository.getAllServicesByBrand(
					item.id,
				)
				if (data.length > 0) {
					data.forEach(el => {
						me.services.push({ id: el.id, text: el.name })
					})
				} else {
					me.$bvToast.toast(
						'No existen servicios registrado para la sede seleccionada',
						{
							title: 'Sin servicios',
							toaster: 'b-toaster-bottom-right',
							variant: 'danger',
							solid: true,
							appendToast: true,
						},
					)
				}
			} catch (error) {
				// console.log(error)
			} finally {
				me.$emit('loadBed', me.locationPatients)
			}
		},
		async loadAreaByService(id) {
			const me = this
      me.resetSelect('2')
			try {
				const { data } = await me.patientBedRepository.getAllAreaByService(id)
				data.forEach(el => {
					me.areas.push({ id: el.id, text: el.name })
				})
			} catch (error) {
				me.$bvToast.toast(
					'No existen areas registrado para el servicio seleccionado',
					{
						title: 'Sin areas',
						toaster: 'b-toaster-bottom-right',
						variant: 'danger',
						solid: true,
						appendToast: true,
					},
				)
				// console.log(error)
			} finally {
				me.$emit('loadBed', me.locationPatients)
			}
		},
		async loadLocationPatient(item) {
			const me = this
      me.resetSelect('3')
			try {
				const res = await me.patientBedRepository.getAllLocationPatient(item.id)
				res.forEach(el => {
					el.list.forEach(elem => {
						me.locationPatients.push({ ...elem, area_id: el.area_id })
					})
				})
			} catch (error) {
				// console.log(error)
				me.$bvToast.toast(
					'No existen camas registradas para el subarea seleccionada',
					{
						title: 'Sin camas',
						toaster: 'b-toaster-bottom-right',
						variant: 'danger',
						solid: true,
						appendToast: true,
					},
				)
			} finally {
				me.$emit('loadBed', me.locationPatients)
			}
		},
    resetSelect(type) {
      const me = this
      if (type == '1') {
        me.services = []
        me.areas = []
        me.locationPatients = []
        me.serviceSelected = null
        me.areaSelected = null
      } else if (type == '2') {
        me.areas = []
        me.locationPatients = []
        me.areaSelected = null
      } else {
        me.locationPatients = []
      }
    },
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.select {
	width: 100%;
}
.title_ {
	font-size: 14px;
	color: #636363;
	font-weight: bold;
}
</style>
